
    import * as echarts from "echarts";
    import {defineComponent, ref, onMounted, reactive, onUnmounted} from 'vue';
    import {getAnalyze} from '@/network/seller';
    import {Moment} from 'moment';
    import moment from "moment";
    import {message} from "ant-design-vue";

    type EChartsOption = echarts.EChartsOption;
    export default defineComponent({
        name: "Danalyze",
        setup() {
            let range = ref<any>({
                'startTime': '',
                'endTime': '',
            });
            /*const value1 = ref<any>();*/
            const department = ref<String>('');
            /*const handlePanelChange = (val: any) => {
                value.value = moment(val).format('YYYY');
                value1.value = undefined;
            };
            const handlePanelChange1 = (val: Moment) => {
                value1.value = moment(val).format('YYYY-MM');
                value.value = undefined;
            };*/
            const timeVal = ref<any>([]);
            const dateFormat="YYYY-MM-DD";
            const handleChange = (value, dateString) => {
                console.log('Formatted Selected Time: ', dateString);
                range.value.startTime = dateString[0] + " 0:0:0";
                range.value.endTime = dateString[1] + " 23:59:59";
                timeVal.value = (dateString[0]&&dateString[1])?[moment(dateString[0], dateFormat), moment(dateString[1], dateFormat)]:[];
            };

            const handleChange1 = (value: string) => {
                //console.log(`selected ${value}`);
                department.value = value;
            };
            const pie = () => {
                getAnalyze({'range': range.value, 'department': department.value}).then((response) => {
                    if (response.status == 'done') {
                        if (department.value == '1') {
                            option = {
                                title: {
                                    text: '每人工单完成数量占比',
                                    subtext: '',
                                    left: 'center'
                                },
                                tooltip: {
                                    trigger: 'item'
                                },
                                legend: {
                                    orient: 'vertical',
                                    left: 'left'
                                },
                                series: [
                                    {
                                        name: '',
                                        type: 'pie',
                                        radius: '50%',
                                        data: response.pieData,
                                        emphasis: {
                                            itemStyle: {
                                                shadowBlur: 10,
                                                shadowOffsetX: 0,
                                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                            }
                                        }
                                    }
                                ]
                            };
                            option1 = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        // Use axis to trigger tooltip
                                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                    }
                                },
                                legend: {},
                                grid: {
                                    left: '3%',
                                    right: '4%',
                                    bottom: '3%',
                                    containLabel: true
                                },
                                xAxis: {
                                    type: 'category',
                                    data: response.usersname,
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                series: [
                                    {
                                        name: '建站',
                                        type: 'bar',
                                        stack: 'total',
                                        label: {
                                            show: true
                                        },
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        data: response.site
                                    },
                                    {
                                        name: '产品',
                                        type: 'bar',
                                        stack: 'total',
                                        label: {
                                            show: true
                                        },
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        data: response.product
                                    }
                                ]
                            };
                        } else if (department.value == '2') {
                            option = {
                                    title: {
                                        text: '视频部完成各类型工单数量占比',
                                        subtext: '',
                                        left: 'center'
                                    },
                                    tooltip: {
                                        trigger: 'item'
                                    },
                                    legend: {
                                        orient: 'vertical',
                                        left: 'left'
                                    },
                                    series: [
                                        {
                                            name: '',
                                            type: 'pie',
                                            radius: '50%',
                                            data:  [
                                                {
                                                    value: response.video.value,
                                                    name: response.video.name,
                                                }, {
                                                    value: response.product.value,
                                                    name: response.product.name,
                                                }
                                            ],
                                            emphasis: {
                                                itemStyle: {
                                                    shadowBlur: 10,
                                                    shadowOffsetX: 0,
                                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                }
                                            }
                                        }
                                    ]
                                };
                            option1 = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        // Use axis to trigger tooltip
                                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                    }
                                },
                                legend: {},
                                grid: {
                                    left: '3%',
                                    right: '4%',
                                    bottom: '3%',
                                    containLabel: true
                                },
                                xAxis: {
                                    type: 'category',
                                    data: [response.username],
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                series: [
                                    {
                                        name: '产品图',
                                        type: 'bar',
                                        stack: 'total',
                                        label: {
                                            show: true
                                        },
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        data: [response.countproduct],
                                    },
                                    {
                                        name: '视频',
                                        type: 'bar',
                                        stack: 'total',
                                        label: {
                                            show: true
                                        },
                                        emphasis: {
                                            focus: 'series'
                                        },
                                        data: [response.countvideo],
                                    }
                                ]
                            };
                        }
                        initChart(department.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const search = () => {
                if (!department.value) {
                    message.error('请选择部门！');
                } else {
                    pie();
                }
            }
            let echart = echarts;
            let option: EChartsOption = {};
            let option1: EChartsOption = {};

            function initChart(type) {
                let chart = echart.init(document.getElementById("myEcharts")!);
                let chart1 = echart.init(document.getElementById("myEcharts1")!);
                // 把配置和数据放这里
                chart.setOption(option);
                chart1.setOption(option1);
                window.onresize = function () {
                    //自适应大小
                    chart.resize();
                    chart1.resize();
                };
                /*if(type==2){
                    chart1.dispose();
                }*/
            }

            onMounted(() => {
                //initChart();
            });
            onUnmounted(() => {
                echart.dispose;
            });
            return {
                timeVal,
                handleChange,
                handleChange1,
                search,
            }
        }
    })
